<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Register v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <vuexy-logo />
                    <h2 class="brand-text text-primary ml-1">
                        {{ appName }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t('pages.register.msg1') }}
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t('pages.register.msg2') }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="registerForm">
                    <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- username -->
                        <b-form-group
                            :label="$t('common.account')"
                            label-for="username"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Username"
                                rules="required"
                            >
                                <b-form-input
                                    id="username"
                                    v-model="username"
                                    :state="errors.length > 0 ? false:null"
                                    name="register-username"
                                    placeholder="useraccount"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group
                            label="Email"
                            label-for="email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="regEmail"
                                    :state="errors.length > 0 ? false:null"
                                    name="register-email"
                                    placeholder="abc@example.com"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group
                            :label="$t('common.password')"
                            label-for="password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        :state="errors.length > 0 ? false:null"
                                        class="form-control-merge"
                                        name="register-password"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="passwordToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox
                                id="register-privacy-policy"
                                v-model="status"
                                name="checkbox-1"
                            >
                                {{ $t('pages.register.msg3') }}
                                <b-link>{{ $t('pages.register.msg4') }}</b-link>
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button
                            variant="primary"
                            block
                            type="submit"
                        >
                            {{ $t('common.signup') }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <span>{{ $t('pages.register.msg5') }} </span>
                    <b-link :to="{name:'Login', params: {} }">
                        <span>{{ $t('pages.register.msg6') }}</span>
                    </b-link>
                </b-card-text>

                <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

                <!-- social buttons -->
                <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            variant="facebook"
            href="javascript:void(0)"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="twitter"
            href="javascript:void(0)"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            variant="google"
            href="javascript:void(0)"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            variant="github"
            href="javascript:void(0)"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
            </b-card>
            <!-- /Register v1 -->
        </div>
    </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
